.App {
  text-align: center;
}

* {
  scroll-behavior: smooth !important;
}

:root {
  --light-mode-primary-color: #493d6e;
  --light-mode-div: #cec8ff;
  --light-mode-body-copy: #818181;
  --dark-mode-primary-color: #181227;
  --dark-mode-div: #9fcaff;
  --dark-mode-body-copy: #c6c1cf;
}
body {
  font-size: 10px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
h1,
h2,
h3 {
  font-family: "Bitter", serif;
}

h4,
h5,
p {
  font-family: Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 1.75rem; /*28px*/
  line-height: 40px;
}

h2 {
  font-size: 1.5rem; /*24px*/
  line-height: 32px;
  margin: 8px 0 0 0;
}

h3 {
  font-size: 1rem; /*16px*/
  line-height: 24px;
}

h4 {
  line-height: 24px;
  font-size: 0.875rem; /*14px*/
}
/* div syntax */
h5 {
  line-height: 24px;
  font-size: 0.625rem; /*10px*/
}

p {
  font-size: 0.75rem; /*12px*/
  line-height: 24px;
  font-weight: 300;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a:visited {
  color: inherit;
}

@media (min-width: 1024px) {
  p {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 4.75rem;
  }
  h2 {
    font-size: 3.875rem;
  }
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1rem;
  }
}
